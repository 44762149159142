<template>
  <form-dialog
    :visible="$route.params.form == 'AddEditForm'"
    :editMode="editMode"
    title="Menu Item"
    :closeDisabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    :submitDisabled="$v.$anyError"
    :error="mainErrorMessage"
    @close="clearFields"
    @submit="submitHandle"
  >
    <h3 class="my-5">Item Information</h3>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="name"
          label="Menu Item Name"
          outlined
          :disabled="!fieldsEnabled"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="restaurantID"
          :items="restaurants"
          item-text="name"
          item-value="id"
          outlined
          label="Restaurant"
          :disabled="!fieldsEnabled"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          v-model="description"
          label="Description (optional):"
          outlined
          height="170"
          :disabled="!fieldsEnabled"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="retailPrice"
          label="Retail Price"
          type="number"
          outlined
          suffix="CAD"
          hide-details="auto"
          :disabled="!fieldsEnabled"
          @change="
            priceHistory.push({
              creationDate: new Date(),
              actualRetailPrice: retailPrice,
            })
          "
        />
        <v-checkbox v-model="isOnSale" label="On sale" />
        <v-text-field
          v-if="isOnSale"
          v-model="promoPrice"
          type="number"
          label="Promotional Price"
          outlined
          suffix="CAD"
          :disabled="retailPrice.length < 1 || !fieldsEnabled"
          @change="
            priceHistory.push({
              creationDate: new Date(),
              actualRetailPrice: retailPrice,
              promo: true,
            })
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="selectedTaxIds"
          :items="restaurantTaxes"
          item-value="id"
          item-text="name"
          multiple
          outlined
          deletable-chips
          chips
          label="Taxes"
          :disabled="!fieldsEnabled"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="menuItemCategoryIds"
          :items="menuItemCategories"
          item-value="id"
          item-text="title"
          multiple
          outlined
          deletable-chips
          chips
          label="Category"
          :disabled="!fieldsEnabled"
        />
      </v-col>
      <v-col v-if="false" cols="4">
        <v-select
          v-model="selectedChannels"
          :items="retaurantChannels"
          item-text="name"
          item-value="id"
          multiple
          outlined
          deletable-chips
          label="Channel"
          chips
          :disabled="!fieldsEnabled"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          v-model="selectedMods"
          :items="modifierLists"
          item-text="name"
          item-value="id"
          multiple
          outlined
          chips
          return-object
          label="Modifier Groups"
          hint="*Select items then drag chips to order."
          :disabled="!fieldsEnabled"
        >
          <template #selection="data">
            <draggable
              :id="data.index"
              :list="selectedMods"
              :move="move"
              v-bind="dragOptionsChips"
              @change="change"
            >
              <v-chip
                :key="data.item.id"
                v-model="selectedMods[data.index]"
                draggable
                close
                @mousedown.stop
                @click.stop
              >
                {{ data.item.name }}
              </v-chip>
            </draggable>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <h3 class="my-5">Availability</h3>
    <v-switch
      v-model="availableAlways"
      :label="availableAlways ? 'Always available' : 'Available at specific times'"
      :disabled="!fieldsEnabled"
    />
    <weekly-hours
      v-if="!availableAlways"
      v-model="hours"
      class="mt-5"
      :error-messages="getFormErrors($v, 'hours')"
      :disabled="!fieldsEnabled"
    />
    <v-switch
      v-model="ageVerificationRequired"
      :label="
        ageVerificationRequired
          ? 'Age-restricted (e.g. alcohol)'
          : 'Not age-restricted'
      "
      :disabled="!fieldsEnabled"
      class="mb-5"
    />
    <h3 class="my-5">Item Image</h3>
    <custom-image-upload
      name="menuItems"
      :imageUrl="imageUrl"
      :allowEdit="fieldsEnabled"
      @imageProcessed="imageUrl = $event"
    />
    <div v-if="settings.inventoryEnabled">
      <h3 class="my-5">Recipe</h3>
      <ingredients v-model="ingredients" />
    </div>
  </form-dialog>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { required, decimal } from "vuelidate/lib/validators"
import { mapState, mapGetters, mapActions } from "vuex"
import { deleteField } from "firebase/firestore"
import draggable from "vuedraggable"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"
import Ingredients from "../../shared/forms/Ingredients.vue"
import WeeklyHours from "../../shared/forms/datetime/WeeklyHours.vue"
import datetimeMixins from "@/AuthenticatedContent/shared/forms/datetime/mixins.js"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
    draggable,
    CustomImageUpload,
    Ingredients,
    WeeklyHours,
  },
  mixins: [datetimeMixins],
  props: {
    supplierItems: {
      type: Array,
      required: true,
    },
  },
  validations() {
    return {
      name: { required },
      restaurantID: { required },
      category: { required },
      nameOnMenu: { required },
      retailPrice: { required, decimal },
    }
  },
  data() {
    return {
      ingredients: [],
      selectedMods: [],
      selectedTaxIds: [],
      selectedChannels: [],
      name: "",
      restaurantID: "",
      category: "",
      nameOnMenu: "",
      retailPrice: "",
      isOnSale: false,
      promoPrice: "",
      description: "",
      ageVerificationRequired: false,
      image: [],
      menuItemCategoryIds: [],
      closeDisabled: false,
      awaitingResponse: false,
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
      imageUrl: "",
      priceHistory: [],
      id: null,
      mainErrorMessage: "",
      availableAlways: true,
      hours: {},
    }
  },
  computed: {
    ...mapState([
      "restaurants",
      "units",
      "menuItemCategories",
      "firebaseRefs",
      "taxes",
      "menuItems",
      "modifierLists",
      "channels",
      "settings",
    ]),
    ...mapGetters(["getRestaurantSyncStatus"]),
    restaurantTaxes() {
      if (!this.restaurantID) return this.taxes
      else
        return this.taxes.filter(tax =>
          tax.restaurantIds.includes(this.restaurantID)
        )
    },
    retaurantChannels() {
      if (!this.restaurantID) return this.channels
      else
        return this.channels.filter(
          channel =>
            channel.name &&
            channel?.restaurantData?.findIndex(
              restaurant => restaurant.restaurantId == this.restaurantID
            ) !== -1
        )
    },
    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      }
    },
    itemToEdit() {
      return this.$route.params.id
        ? this.menuItems?.find(item => item.id == this.$route.params.id)
        : null
    },
    editMode() {
      return this.itemToEdit != null
    },
    fieldsEnabled() {
      if (!this.editMode) {
        return true
      }
      return !this.getRestaurantSyncStatus(this.itemToEdit.restaurantId)
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateMenuItems"]),
    async submitHandle() {
      this.closeDisabled = true
      this.awaitingResponse = true
      try {
        const objectToSave = {
          id: this.id,
          name: this.name,
          restaurantId: this.restaurantID,
          ingredients: [...this.ingredients],
          retailPriceMoney: {
            amount: Math.round(parseFloat(this.retailPrice) * 100),
            currency: "CAD",
          },
          isSnoozed: false,
          snoozeUntil: "",
          description: this.description,
          modifierLists: this.cleanMods(this.selectedMods),
          taxIds: this.selectedTaxIds,
          ageVerificationRequired: this.ageVerificationRequired || false,
          itemImage: { name: "Item Image", url: this.imageUrl },
          priceHistory: this.priceHistory,
          channelData: this.prepChannelData(),
        }
        if (this.isOnSale) {
          objectToSave.promoPriceMoney = {
            amount: Math.round(parseFloat(this.promoPrice) * 100),
            currency: "CAD",
          }
        } else {
          objectToSave.promoPriceMoney = deleteField()
        }
        if (!this.availableAlways) {
          objectToSave.hours = this.hours
        } else {
          objectToSave.hours = deleteField()
        }
        if (this.menuItemCategoryIds) {
          objectToSave.menuItemCategoryIds = this.menuItemCategoryIds
        } else {
          objectToSave.menuItemCategoryIds = deleteField()
        }
        await this.updateMenuItems(objectToSave)
          .then(() => {
            this.$emit("close")
            this.clearFields()
          })
          .catch(error => {
            this.mainErrorMessage = "Error saving menu item: " + error.message
            console.error("Error: ", error)
          })
        this.awaitingResponse = this.closeDisabled = false
      } catch (error) {
        this.mainErrorMessage = "Error saving menu item: " + error.message
        console.error("Error: ", error)
        this.awaitingResponse = this.closeDisabled = false
      }
    },
    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      }
    },
    change(value) {
      if (value.removed) {
        // insert
        this.selectedMods.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.selectedMods[this.dragged.from]
        )
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.selectedMods.splice(this.dragged.from, 1)
        // RTL
        else this.selectedMods.splice(this.dragged.from + 1, 1)
      }
    },
    async prepEdit() {
      this.id = this.itemToEdit.id
      this.name = this.itemToEdit.name
      this.description = this.itemToEdit.description
      this.restaurantID = this.itemToEdit.restaurantId
      this.category = this.itemToEdit.category
      this.nameOnMenu = this.itemToEdit.nameOnMenu
      this.menuItemCategoryIds = this.itemToEdit.menuItemCategoryIds
      this.ingredients =
        this.itemToEdit.ingredients?.length > 0 ? this.itemToEdit.ingredients : []
      this.retailPrice = (
        parseInt(this.itemToEdit.retailPriceMoney?.amount || 0) / 100
      )?.toFixed(2)
      this.promoPrice = (
        parseInt(
          this.itemToEdit.promoPriceMoney?.amount ||
            this.itemToEdit.retailPriceMoney?.amount ||
            0
        ) / 100
      )?.toFixed(2)
      this.isOnSale = this.promoPrice !== this.retailPrice ? true : false
      this.image.push(this.itemToEdit.itemImage)
      this.imageUrl = this.itemToEdit.itemImage?.url || ""
      this.selectedMods = this.getMods(this.itemToEdit.modifierLists)
      this.selectedTaxIds =
        this.itemToEdit.taxIds?.length > 0
          ? this.getTaxes(this.itemToEdit.taxIds).map(tax => tax.id)
          : []
      this.priceHistory = this.itemToEdit.priceHistory
        ? this.itemToEdit.priceHistory
        : []
      // this.selectedChannels = this.itemToEdit.channelData
      this.availableAlways = this.itemToEdit.hours ? false : true
      this.hours = this.itemToEdit.hours || {
        sun: [this.getEmptyDateRange()],
        mon: [this.getEmptyDateRange()],
        tue: [this.getEmptyDateRange()],
        wed: [this.getEmptyDateRange()],
        thu: [this.getEmptyDateRange()],
        fri: [this.getEmptyDateRange()],
        sat: [this.getEmptyDateRange()],
      }
      this.ageVerificationRequired = this.itemToEdit.ageVerificationRequired || false
    },
    getTaxes(taxes) {
      return taxes.map(id => this.taxes.find(obj => obj.id == id))
    },
    getMods(mods) {
      return mods.map(mod => {
        const fullModList = this.modifierLists.find(
          modifier => modifier.id == mod.modifierListId
        )
        return Object.assign({}, fullModList, {
          min: mod.minSelectedModifiers,
          max: mod.maxSelectedModifiers,
        })
      })
    },
    cleanMods(modList) {
      var cleanedModList = []
      for (let i = 0; i < modList.length; i++) {
        cleanedModList.push({
          enabled: true,
          maxSelectedModifiers: modList[i].max,
          minSelectedModifiers: modList[i].min,
          modifierListId: modList[i].id,
        })
      }
      return cleanedModList
    },
    prepChannelData() {
      let channelData = []
      this.selectedChannels?.forEach(channel =>
        channelData.push({ channelId: channel, channelMenuItemId: this.menuItemID })
      )
      return channelData
    },
    clearFields() {
      this.$v.$reset()
      this.id = ""
      this.name = ""
      this.description = ""
      this.restaurantID = ""
      this.category = ""
      this.ingredients = []
      this.menuItemCategoryIds = []
      this.nameOnMenu = ""
      this.retailPrice = ""
      this.promoPrice = ""
      this.selectedMods = []
      this.selectedTaxIds = []
      this.priceHistory = []
      this.image = []
      this.hours = {}
      this.availableAlways = true
      this.ageVerificationRequired = false
      this.selectedChannels = []
      this.imageUrl = ""
      this.isOnSale = false
      this.mainErrorMessage = ""
      if (this.$route.params.form != null) {
        this.$router.push({ params: { form: null, id: null } })
      }
      this.$emit("close")
    },
  },
}
</script>
